<template>
  <div class="sip-test">
    <div class="sip-test-body">
      <div class="sip-test-call">
        <input ref="input" v-model="outgoingNumber" :disabled="!['initial', 'idle'].includes(phoneState)" placeholder="Телефон"
               type="tel" @focus="onFocus" @keypress.enter="call"/>
        <button :data-tooltip="!sipRegistered ? 'Не забудь выйти на линию.' : null" :disabled="!sipRegistered || !['initial', 'idle'].includes(phoneState)"
                @click="call">
          <Icon name="outbound-call"/>
        </button>
      </div>
      <div class="sip-test-sms">
        <button class="button" data-tooltip="Отправить СМС" data-tooltip-small @click="sendSMS">
          SMS
        </button>
      </div>
    </div>

    <SmsModal v-if="SmsModalShow" :data="{ phone : outgoingNumber }" @close="SmsModalShow=false"/>
    <AudioModal v-if="CallAudioModalShow" :id="outgoingCallId" :type="'call'" url="/call-record/"
                @close="CallAudioModalShow=false"/>
    <CallSidebar v-if="phoneState === 'call-in-progress' || hasCall" :data="sidebarData" @listenAudio="listenAudio"
                 @sendSMS="sendSMS"/>
  </div>
</template>

<script>
import Icon from '../components/ui/Icon';
import {mapActions, mapGetters, mapState} from 'vuex';
import CallSidebar from '../components/call/CallSidebar';
import SmsModal from '../components/call/SmsModal';
import AudioModal from '../components/call/AudioModal';

export default {
  name: 'SipTest',
  components: {CallSidebar, Icon, SmsModal, AudioModal},
  data() {
    return {
      outgoingNumber: '',
      callStarted: null,
      callFinished: null,
      now: Date.now(),
      SmsModalShow: false,
      CallAudioModalShow: false,
      outgoingCallId: null
    }
  },
  methods: {
    ...mapActions({initOutgoingCall: 'call/initOutgoingCall', setTitle: 'setTitle'}),
    sendSMS() {
      this.SmsModalShow = true;
    },
    listenAudio() {
      this.CallAudioModalShow = true;
    },
    async call() {
      await this.initOutgoingCall(this.outgoingNumber)
    },
    onFocus() {
      if (/^(\+|\+7)?$/.test(this.outgoingNumber)) {
        this.outgoingNumber = '+7'
      }
    }
  },
  computed: {
    ...mapState({
      sipRegistered: state => state.call.registered,
      phoneState: state => state.call.phoneState,
      outgoingCallInfo: state => state.call.outgoingCallInfo
    }),
    ...mapGetters({
      hasCall: 'call/hasCall'
    }),
    sidebarData() {
      return {
        session: {
          name: this.outgoingCallInfo.callId,
          time: this.callStarted ? new Date(this.callStarted).toLocaleString('ru-RU', {
            hour: 'numeric',
            minute: 'numeric'
          }) : null,
        },
        time: this.callStarted ? new Date((this.callFinished ?? this.now) - this.callStarted)
          .toLocaleString('ru-RU', {minute: 'numeric', second: 'numeric'}) : null,
        phone: this.outgoingCallInfo.phoneNumber,
        person: this.outgoingCallInfo.fullName
      }
    }
  },
  watch: {
    phoneState(val) {
      switch (val) {
      case 'call-in-progress':
        this.callStarted = this.now;
        this.callFinished = null;
        break;
      case 'calling':
        this.callStarted = null;
        break;
      case 'idle':
        this.callFinished = this.now;
        break
      }
    }
  },
  created() {
    setInterval(() => {
      this.now = Date.now()
    }, 1000);
  },
  mounted() {
    this.setTitle({text: 'Звонилка'});
  },
}
</script>

<style lang="less" scoped>
.sip-test {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &-body {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-call {
    border-radius: 7px;
    border: 1px solid @cold-grey-light;
    background-color: white;
    display: flex;
    transition-duration: .2s;

    &:focus-within {
      box-shadow: 0 2px 14px rgba(85, 85, 85, 0.05);
      border-color: @green;
    }

    input {
      border: none;
      background: none;
      font-family: inherit;
      font-size: 1.15rem;
      color: @cold-grey-dark;
      padding: 16px 20px;
      border-radius: 7px 0 0 7px;

      &::placeholder {
        font-size: 1.05rem;
        color: @cold-grey;
      }

      &:disabled {
        opacity: .7;
        cursor: not-allowed;
      }
    }

    button {
      border: none;
      background: none;
      width: 50px;
      border-left: 1px solid @cold-grey-light;
      padding: 0;
      cursor: pointer;
      border-radius: 0 7px 7px 0;

      svg {
        display: block;
        color: @green;
        width: 16px;
        height: 16px;
        margin: auto;
      }

      &:disabled {
        opacity: .6;
        cursor: not-allowed;
      }

      &:hover {
        background-color: #fcfcfc;
      }
    }
  }

  &-sms {
    .button {
      border: none;
      background: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      border-radius: 30px;
      --color: @red;
      position: relative;
      font-family: inherit;
      margin: 0 10px 0 10px;
      color: white;
      cursor: pointer;
      background-color: var(--color);
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

      &:disabled {
        background-color: @red-light;
        cursor: not-allowed;
      }
    }
  }
}
</style>
