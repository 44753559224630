import * as SIP from 'sip.js';
import {Mutex} from 'async-mutex'

const incomingMutex = new Mutex();
const SipPlugin = {
  install(Vue, store) {
    Vue.prototype.$sip = {

      ua: null,
      audio: new Audio(),
      authPromise: null,
      authTimer: null,


      authAsync(login, password, timeout = 5000) {

        console.log(`${login} authenticating`);
        let connectPromise = new Promise((resolve, reject) => {
          this.authPromise = {
            resolve: resolve,
            reject: reject
          }
          this.authTimer = setTimeout(() => {
            reject('timed out');
          }, timeout);
        });

        const transportOptions = {
          server: `wss://${process.env.VUE_APP_ATS_HOST}:7443`,
        };

        const uri = SIP.UserAgent.makeURI(`sip:${login}@${process.env.VUE_APP_ATS_HOST}`)
        const UA = new SIP.UserAgent({
          authorizationUsername: login,
          authorizationPassword: password,
          transportOptions,
          uri,
          delegate: {
            onInvite: this.onIncoming,
            addListeners: this.addStateListener,
            addCallQueueListeners: this.addCallQueueListener,
            toggleMute: this.toggleMute,
            attachMedia: this.attachMedia,
            getAudio: () => this.audio,
            getMutex: () => this.incomingMutex
          },
          logBuiltinEnabled: false,
          sessionDescriptionHandlerFactoryOptions: {
            iceGatheringTimeout: 500
          }
        });

        const registerer = new SIP.Registerer(UA);
        registerer.expires = 60

        registerer.stateChange.addListener(state => {
          console.log(`state changed to ${state}`);
          switch (state) {
          case SIP.RegistererState.Registered:
            store.dispatch('call/setRegistered', true)
            store.commit('call/setPhoneState', 'idle')
            clearTimeout(this.authTimer);
            this.authPromise.resolve()
            break;
          default:
            store.dispatch('call/setRegistered', false)
            store.commit('call/setPhoneState', 'initial')
          }
        })

        UA.start().then(() => {
          registerer.register();
        })

        this.ua = UA;

        window.sipDebug = this;
        console.log(`${login} authenticate finished`);
        return connectPromise;
      },
      test_call() {
        try {
          const uri = SIP.UserAgent.makeURI(`sip:call_test@${process.env.VUE_APP_ATS_HOST}`);
          const inviter = new SIP.Inviter(this.ua, uri);
          inviter.invite()
          store.commit('call/setPhoneState', 'calling')
          store.commit('call/setSipSession', inviter)
          this.addStateListener(inviter);
        } catch (ex) {
          throw new Error(ex);
        }
      },
      call(number, headers) {
        try {
          const uri = SIP.UserAgent.makeURI(`sip:${number}@${process.env.VUE_APP_ATS_HOST}`);
          const inviter = new SIP.Inviter(this.ua, uri, {extraHeaders: headers});
          inviter.invite()
          store.commit('call/setPhoneState', 'calling')
          store.commit('call/setSipSession', inviter)
          this.addStateListener(inviter);
        } catch (ex) {
          throw new Error(ex);
        }
      },
      attachMedia(session) {
        const audio = this.getAudio();
        const remoteStream = new MediaStream();
        session.sessionDescriptionHandler.peerConnection.getReceivers().forEach((receiver) => {
          if (receiver.track) {
            remoteStream.addTrack(receiver.track);
          }
        });
        audio.srcObject = remoteStream;
        audio.play();
      },
      toggleMute(val = undefined) {
        store.state.call.sipSession.sessionDescriptionHandler.peerConnection.getSenders().forEach((stream) => {
          stream.track.enabled = typeof val === 'boolean' ? !val : !stream.track.enabled;
          store.commit('call/setMute', !stream.track.enabled)
        })
      },
      addStateListener(session) {
        
        const that = this;
        session.stateChange.addListener(state => {
          console.log(`session state listener: ${state}`);
          switch (state) {
          case SIP.SessionState.Established:
            that.attachMedia(session);
            store.commit('call/setPhoneState', 'call-in-progress')
            break;
          case SIP.SessionState.Terminated:
            store.commit('call/setPhoneState', 'idle')
            store.commit('call/setSipSession', null)
            that.getAudio().pause()
            store.commit('call/popFromCallQueue')
            break;
          case SIP.SessionState.Establishing:
            store.commit('call/setPhoneState', 'establishing')
            break;
          default:
            break;
          }
          console.log(`session state listener finished`);
        })

      },
      addCallQueueListener(invitation) {
        invitation.stateChange.addListener(state => {
          console.log(`call queue listener: ${state}`);
          switch (state) {
          case SIP.SessionState.Established:
            break;
          case SIP.SessionState.Terminated:
            store.commit('call/removeFromCallQueue', invitation)
            break;
          default:
            break;
          }
          console.log(`call queue listener finished`);
        })
      },
      endCall() {
        console.log('endCall starting');
        const session = store.state.call.sipSession;
        console.log(session);
        switch (session.state) {
        case SIP.SessionState.Initial:
        case SIP.SessionState.Establishing:
          if (session instanceof SIP.Inviter) {
            session.cancel();
          } else {
            session.reject();
          }
          break;
        case SIP.SessionState.Established:
          session.bye();
          break;
        case SIP.SessionState.Terminating:
        case SIP.SessionState.Terminated:
          break;
        }
        store.commit('call/setSipSession', null);
        store.commit('call/setPhoneState', 'idle');
        this.audio.pause();
        console.log('endCall finished');
      },
      onIncoming(invitation) {
        incomingMutex.runExclusive(() => {
          console.log('incoming call');
          const incomingAllowed = store.getters['call/incomingAllowed']
          //const session = store.state.call?.session
          //if((session == null || session == undefined) && (store.state.call.phoneState !== 'calling')) {
          if (incomingAllowed) {
            console.log('incoming allowed');
            store.commit('call/setPhoneState', 'incoming')
            store.commit('call/setSipSession', invitation)
            this.addListeners(invitation)
          } else {
            console.log('incoming not allowed');
            invitation.progress()
            store.commit('call/addToCallQueue', invitation)
            this.addCallQueueListeners(invitation)
          }
        })
      },
      async acceptCall() {
        console.log('accepting call');
        store.commit('call/setPhoneState', 'establishing')
        try {
          await store.state.call.sipSession.accept();
        } catch (ex) {
          console.log(`error in acceptCall ${ex}`);
          store.commit('call/setSipSession', null)
          store.commit('call/setPhoneState', 'idle')
          store.commit('call/resetSession')
          throw new Error(ex);
        }
      },
      getAudio() {
        return this.audio
      },
      disconnect() {
        return this.ua.stop()
      },
      transferCall(number) {
        console.log('transferring call');
        const target = SIP.UserAgent.makeURI(`sip:${number}@${process.env.VUE_APP_ATS_HOST}`);
        store.state.call.sipSession.refer(target)
      },
      processQueue() {
        console.log('processing call queue');
        store.commit('call/popFromCallQueue')
      },
      processQueueItem(invitation) {
        console.log('processing call queue item');
        this.ua.delegate.onInvite(invitation)
      }
    }
  }
}

export default SipPlugin;
