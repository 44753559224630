<template>
  <div class="header-session">
    <div class="info">
      <div class="person-icon">
        <Icon v-if="!getPhoto" name="person"/>
        <img v-if="getPhoto" :src="getPhoto" class="photo" alt=""/>
      </div>
      {{ full_name }}
    </div>
    <button class="log-out" @click="logout">
      <Icon name="log-out"/>
    </button>
  </div>
</template>

<script>
import Icon from '../ui/Icon';
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'CallHeaderSession',
  components: {Icon},
  methods: {
    ...mapActions(['logOut']),
    async logout() {
      await this.logOut();
    }
  },
  computed: {
    ...mapGetters({
      full_name: 'full_name',
      getPhoto: 'getPhoto'
    }),
  },
}
</script>

<style lang="less" scoped>
.header-session {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0 15px;
  border-left: 1.5px solid @warm-grey-light;
  font-size: .85em;
  color: @cold-grey;
  width: 342px;

  .info {
    display: flex;
    align-items: center;
  }

  .person-icon {
    width: 24px;
    height: 24px;
    background-color: @cold-grey;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    margin-right: 8px;
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.1);
  }

  .log-out {
    appearance: none;
    background: none;
    border: none;
    color: inherit;
    margin-left: 10px;

    svg {
      transform: rotate(180deg);
    }
  }

  .photo {
    width: 24px;
    height: 24px;
    border-radius: 12px;
  }
}
</style>
